import { register } from "register-service-worker";

// Verifica si el protocolo es HTTPS o si estás en localhost
if (window.location.protocol === "https:") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("PWA lista para usarse sin conexión.");
    },
    registered() {
      console.log("Service Worker registrado.");
    },
    cached() {
      console.log("Contenido cacheado para uso sin conexión.");
    },
    updatefound() {
      console.log("Nueva versión detectada. Descargando...");
    },
    updated(registration) {
      console.log("Nueva versión disponible. Recarga la página.");
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    offline() {
      console.log("No hay conexión a internet. Modo offline activado.");
    },
    error(error) {
      console.error("Error en el Service Worker:", error);
    },
  });
} else {
  console.log(
    "Service Worker no registrado: la aplicación no se ejecuta en un entorno seguro (HTTP)."
  );
}
