import "firebase/remote-config"; // Importar Remote Config para Firebase 7
import firebase from "firebase/app";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { Filesystem, Directory, Permissions } from "@capacitor/filesystem";

// 🔹 **Inicializar Remote Config en Firebase 7**
const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 0, // Descargar la actualización cada vez que se abre la app
};

// 🔹 **Valores por defecto en caso de que Firebase no responda**
remoteConfig.defaultConfig = {
  update_version: "1.0.0", // Versión actual
  update_url: "", // URL de actualización
};

// 📌 **Función para solicitar permisos de almacenamiento**
async function requestPermissions() {
  try {
    const status = await Filesystem.requestPermissions();
    if (status.publicStorage !== "granted") {
      console.error("❌ Permiso denegado por el usuario.");
      return false;
    }
    console.log("✅ Permiso concedido.");
    return true;
  } catch (error) {
    console.error("❌ Error al solicitar permisos:", error);
    return false;
  }
}

// 📌 **Función para verificar y aplicar la actualización**
export async function checkForUpdate() {
  try {
    console.log("🔄 Verificando actualizaciones...");
    await remoteConfig.fetch();
    await remoteConfig.activate();

    const latestVersion = remoteConfig.getValue("update_version").asString();
    const updateUrl = remoteConfig.getValue("update_url").asString();
    const currentVersion = "1.0.0"; // Versión actual instalada

    console.log("📌 Versión en RemoteConfig:", latestVersion);
    console.log("📌 URL de actualización:", updateUrl);

    if (latestVersion !== currentVersion && updateUrl) {
      console.log("📌 Nueva versión disponible:", latestVersion);

      // 🔹 **Solicitar permisos antes de descargar**
      const hasPermission = await requestPermissions();
      if (!hasPermission) {
        alert(
          "Es necesario conceder permisos de almacenamiento para actualizar."
        );
        return;
      }

      // 🔹 **Descargar la actualización**
      const update = await CapacitorUpdater.download({
        version: latestVersion,
        url: updateUrl,
      });

      if (!update || !update.id) {
        console.error("❌ Error: No se obtuvo un ID de actualización válido.");
        return;
      }

      console.log("✅ Actualización descargada con ID:", update.id);

      // 🔹 **Guardar el ID en localStorage**
      localStorage.setItem("updateId", update.id);

      // 🔹 **Aplicar la actualización**
      await applyUpdate(update.id);
    } else {
      console.log("✅ La app está actualizada o no hay una URL válida.");
    }
  } catch (error) {
    console.error("❌ Error verificando la actualización:", error);
  }
}

// 📌 **Función para aplicar la actualización**
async function applyUpdate(updateId) {
  try {
    console.log("📌 Aplicando actualización con ID:", updateId);

    // 🔹 **Verificar si el ID es válido**
    const updates = await CapacitorUpdater.get().catch(() => []);
    const updateExists = updates.some((u) => u.id === updateId);

    if (!updateExists) {
      console.warn(
        "⚠️ Advertencia: El ID de la actualización no se encontró. Se aplicará de todos modos."
      );
    }

    await CapacitorUpdater.set({ id: updateId });

    console.log("✅ Aplicación configurada con la actualización.");

    // 🔹 **Forzar la recarga manualmente**
    setTimeout(() => {
      console.log("♻️ Recargando la app...");
      window.location.reload();
    }, 2000);
  } catch (error) {
    console.error("❌ Error aplicando la actualización:", error);
  }
}

// 📌 **Escuchar evento cuando la actualización está lista**
CapacitorUpdater.addListener("updateAvailable", async (update) => {
  try {
    const updateId = update?.id || localStorage.getItem("updateId");
    if (!updateId) {
      console.error("❌ Error: Evento de actualización sin ID válido.");
      return;
    }

    console.log("✅ Nueva actualización lista con ID:", updateId);
    await applyUpdate(updateId);
  } catch (error) {
    console.error("❌ Error aplicando la actualización:", error);
  }
});

// 📌 **Escuchar evento cuando la actualización se ha descargado**
CapacitorUpdater.addListener("updateDownloaded", async (update) => {
  try {
    const updateId = update?.id || localStorage.getItem("updateId");

    if (!updateId) {
      console.error(
        "❌ Error: Evento de actualización descargada sin ID válido."
      );
      return;
    }

    console.log("✅ Actualización descargada con ID:", updateId);
  } catch (error) {
    console.error("❌ Error en el evento updateDownloaded:", error);
  }
});

// 📌 **Notificar que la aplicación está lista para recibir actualizaciones**
CapacitorUpdater.notifyAppReady();
