<template>
    <v-app>
        <v-container grid-list-xs>
            <barrasuperior />
        </v-container>
        <v-container></v-container>
        <router-view></router-view>
        <v-container></v-container>
        <!-- Notificación de actualización -->
        <v-snackbar v-model="updateAvailable" color="primary" timeout="-1">
            Nueva versión disponible.
            <v-btn text @click="refreshApp">Actualizar</v-btn>
        </v-snackbar>
        <v-footer dark padless absolute dense>
            <v-card class="flex" flat tile>

                <v-card-text class="py-2 white--text text-center">
                    {{ new Date().getFullYear() }} — <strong>Domotica Inc. asdasdsa</strong>
                </v-card-text>
            </v-card>
        </v-footer>

    </v-app>
</template>

<script>
import barrasuperior from '../src/components/barrasuperior.vue'
export default {
    name: 'App',
    components: {
        barrasuperior
    },
    data() {
        return {
            updateAvailable: false, // Estado para mostrar el mensaje de actualización
            registration: null, // Guardará la información del Service Worker
        };
    },
    mounted() {
        // Detecta si hay una nueva versión disponible
        document.addEventListener("swUpdated", (event) => {
            this.registration = event.detail;
            this.updateAvailable = true;
        });
    },
    methods: {
        refreshApp() {
            if (this.registration && this.registration.waiting) {
                this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
                window.location.reload();
            }
        }
    }
};
</script>
